
.heading_rating {
  font-size: 25px;
  margin-right: 25px;
}

.fa {
    font-size: 25px;
  }
  
  .checked {
    color: orange;
  }

  .side {
    float: left;
    width: 15%;
    margin-top:10px;
    font-size: 14px;
  }
  
  .middle {
    margin-top:10px;
    float: left;
    width: 70%;
  }
  
  /* Place text to the right */
  .right {
    text-align: right;
  }

  
/* The bar container */
.bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
  }
  
  /* Individual bars */
  .bar-5 {width: 60%; height: 18px; background-color: #04AA6D;}
  .bar-4 {width: 30%; height: 18px; background-color: #2196F3;}
  .bar-3 {width: 10%; height: 18px; background-color: #00bcd4;}
  .bar-2 {width: 4%; height: 18px; background-color: #ff9800;}
  .bar-1 {width: 15%; height: 18px; background-color: #f44336;}

  .rating-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color:  #ccc;
  }

  .on {
    color: #000;
  }
  .off {
    color: #ccc;
  }


  
/* Responsive layout - make the columns stack on top of each other instead of next to each other */

  @media (max-width: 400px) {
    .side, .middle {
      width: 100%;
    }
    .right {
      display: none;
    }
  }
