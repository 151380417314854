/* Custom styles for reviews list */
.reviews-list {
  list-style: none;
  padding: 0;
}

.review-item {
  margin-bottom: 10px;
}

.avatar {
  border: 2px solid #ccc;
}

.review-content {
  flex-grow: 1;
}

/* Optional: Add additional styles as needed */
