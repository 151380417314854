* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
      Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .main-container {
    padding: 12px;
    padding-top: 5rem;
    
  }

  .show-transition{
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .show-transition.show {
    opacity: 1;
    transform: translateX(0);
  }
  

  /* HEADING */

  .heading {
    text-align: center;
  }

  .heading__title {
    font-weight: 600;
  }

  .heading__credits {
    margin: 10px 0px;
    color: #888888;
    font-size: 25px;
    transition: all 0.5s;
  }

  .heading__link {
    text-decoration: none;
    cursor: pointer;
  }

  .vertical_align_middle {
    vertical-align: middle;
  }

  .heading__credits .heading__link {
    color: inherit;
  }
  
  /* CARDS */

  .cards_ct {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
  }

  .card_ct {
    margin: 6px;
    padding: 6px;
    width: 500px;
    min-height: 200px;
    display: grid;
    /* grid-template-rows: 20px 50px 1fr 1fr 50px; */
    /* grid-template-rows: 20px 50px 1.7rem 1fr 50px; */
    grid-template-rows: auto auto auto auto auto;
    /* gap: 0.5rem; */
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }

  .card_ct:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
  }

  .card__link,
  .card__fee,
  .card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }

  .card__ratings {
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin: 1rem 0;
    overflow-x: scroll;
    padding: 0 0 0 1rem;
    /* scroll-padding-left: 1rem; */
    scroll-snap-type: x mandatory;
    /* Hide on Firefox */
    scrollbar-width: none
  }

  /* Hide on WebKit browsers */
  .card__ratings::-webkit-scrollbar {
    display: none;
  }

  .card__link::after {
    position: absolute;
    top: 25px;
    left: 0;
    content: "";
    width: 0%;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 0.5s;
  }

  .card__link:hover::after {
    width: 100%;
  }

  .card__fee {
    grid-row: 1/2;
    justify-self: end;
    font-size: 0.8rem;
    white-space: nowrap;
  }

  .card__icon {
    grid-row: 2/3;
    font-size: 1.3rem;
    font-weight: 300;
    text-transform: capitalize;
  }

  .card__title {
    grid-row: 3/4;
    font-weight: 500;
    color: #ffffff;
    font-size: 1.3rem;
    text-transform: capitalize;
  }

  .card__ratings {
    grid-row: 4/5;
    align-self: center;
  }

  .ratings{
    border-radius: 12px;
    background-color: lightgoldenrodyellow;
    font-size: 0.8rem;
    padding: 5px;
    list-style-type: none;
    /* float: left; */
    margin: 5px;
    color: black;
    /* font-weight: 400; */
    /* padding: 0 1rem 0 0; */
    scroll-snap-align: start;
    scroll-snap-stop: always;
    white-space: nowrap;
    cursor: pointer;
  }

  .card__apply {
    grid-row: 5/6;
    align-self: center;
  }

  /* CARD BACKGROUNDS */

  .card-1 {
    /* background: radial-gradient(circle,#3fbafe,#3d8a90); */
    /* background: linear-gradient(270deg, #1CB5E0 0%, #000851 100%); */
    background: linear-gradient(135deg, #FF0000, #FF7300, #FF007F, #7700FF, #00D8FF);
  }

  .card-2 {
    /* background: radial-gradient(circle, #fa99b2, #cc697c); */
    /* background: radial-gradient(circle, #ff5e62, #ff9966); */
    /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(237,3,32,0.87) 20.8%, rgba(242,121,1,0.84) 74.4% ); */
    background: linear-gradient(135deg, #005C97, #363795, #4CA1AF, #76B852, #D4E157);

  }

  .card-3 {
    /* background: radial-gradient(circle, #b69efe, #453670); */
    /* background: radial-gradient(circle, #614385, #516395); */
    /* background-image: linear-gradient( 288deg,  rgba(0,85,255,1) 1.5%, rgba(4,56,115,1) 91.6% ); */
    background: linear-gradient(135deg,#20002C, /* Dark Purple */#C31432, /* Crimson */#F09819, /* Bright Orange */#FFD194, /* Warm Peach */#E94057  /* Pink */);
  }

  .card-4 {
    /* background: radial-gradient(circle, #368368, #27655f); */
    /* background: radial-gradient(circle, #56ab2f, #a8e063); */
    /* background-image: linear-gradient( 109.6deg,  rgba(61,131,97,1) 11.2%, rgba(28,103,88,1) 91.1% ); */
    /* background-image: linear-gradient( 73.1deg,  rgba(34,126,34,1) 8%, rgba(99,162,17,1) 86.9% ); */
    background: linear-gradient(135deg, #232526, /* Dark Gray */ #414345, /* Medium Gray */ #6A82FB, /* Blue */ #FC5C7D, /* Pink */ #FFB94F /* Golden Yellow */);

  }

  .card-5 {
    /* background: radial-gradient(circle, #a184c5, #763064); */
    /* background: radial-gradient(circle, #8e9eab, #e27d5e); */
    /* background: radial-gradient(circle, #FAD961, #F76B1C); */
    /* background-image: linear-gradient( 110.1deg,  rgba(30,2,83,1) 44.2%, rgba(198,55,160,1) 138.2% ); */
    background: linear-gradient(135deg, #4CAF50, /* Green */ #8BC34A, /* Lime Green */ #C2E59C, /* Soft Green */ #76B852, /* Olive Green */ #D4FC79 /* Pale Green */);

  }

  .card-6 {
    /* background: radial-gradient(#1fe4f5, #3fbafe); */
    /* background: radial-gradient(circle, #614385, #516395); */
    background: linear-gradient(135deg, #FDC830, /* Yellow */ #F37335, /* Orange */ #FF0080, /* Neon Pink */ #EA5455, /* Coral Red */ #72147E /* Deep Purple */);

  }

  .card-7 {
    /* background: radial-gradient(#fbc1cc, #fa99b2); */
    /* background: radial-gradient(circle, #ff6b6b, #556270); */
    background: linear-gradient(135deg, #FDB813, /* Golden Yellow */ #F6A623, /* Orange */ #FFB75E, /* Light Orange */ #ED8F03, /* Burnt Yellow */ #FFB700 /* Bright Yellow */);

  }

  .card-8 {
    /* background: radial-gradient(#76b2fe, #b69efe); */
    /* background: radial-gradient(circle, #ffc3a0, #ffafbd); */
    background: linear-gradient(135deg, #4CAF50, /* Green */ #8BC34A, /* Lime Green */ #C2E59C, /* Soft Green */ #76B852, /* Olive Green */ #D4FC79 /* Pale Green */);

  }

  .card-9 {
    /* background: radial-gradient(#60efbc, #58d5c9); */
    /* background: radial-gradient(circle, #70e1f5, #ffd194); */
    background: linear-gradient(135deg, #FF0000, #FF7300, #FF007F, #7700FF, #00D8FF);

  }

  .card-0 {
    /* background: radial-gradient(circle, #f14e4e, #b04545); */
    /* background: radial-gradient(circle, #f14e4e, #b04545); */
    /* background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% ); */
    background: linear-gradient(135deg, #FDB813, /* Golden Yellow */ #F6A623, /* Orange */ #FFB75E, /* Light Orange */ #ED8F03, /* Burnt Yellow */ #FFB700 /* Bright Yellow */);
  }

  /* this is for subscripion detail page card */
  .card-11 {
    background: linear-gradient(135deg, #4CAF50, /* Green */ #8BC34A, /* Lime Green */ #C2E59C, /* Soft Green */ #76B852, /* Olive Green */ #D4FC79 /* Pale Green */);

  }


  
  .profile__image{
    width: 5em;
    height: 5em;
    border-radius: 50%;
    object-fit: cover;
  }

  .detail_page__image{
    width: 17rem;
    height: 17rem;
    border-radius: 50%;
    object-fit: cover;
  }

  .thumbnail{
    width: 45px;
    height: 45px;
    object-fit: cover;
  }

  .circle{
    border-radius: 50%;
  }

  .input_radius{
    border-radius: 25px;
  }

  .clear_outer {
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 1000;
    top: 20px;
    right:20px;
  }

  .clear_inner{
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 50%;
  }

  .custom-search-input {
    border-radius: 20px !important;
    height: 48px;
  }
  
  #searchDiv ul > li:nth-child(2n+2){
    /* background:#e6f0f4; */
    background: lightyellow;
  }

  #searchDiv ul > li:hover{
    background:lightblue;
    transform: scale(1.02);
  }

  .filtered-box{
    position: absolute;
    z-index: 1;
    transition: 0.7s;
    font-size: medium;
    top: 2.4rem;
    width: 100%;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
    cursor: pointer;
    border-radius: 10px;
  }

  .center{
    margin:0;
    height:100vh; /* use 100% of the height of the viewport */
    display:grid;             
    place-items: center;
  }

  .capitalize{
    text-transform: capitalize;
  }

  .capitalize-first::first-letter {
    text-transform: uppercase;
  }

  .clear:hover{
    transform: scale(1.02);
  }

  .close{
    border: 0;
    border-radius: 5px;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .pl-1{
    padding-left: 1rem;
  }

  .card-plan {
    margin: 6px;
    padding: 6px;
    width: 500px;
    min-height: 200px;
    display: grid;
    /* grid-template-rows: 20px 50px 1fr 1fr 50px; */
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
  }


  .otpInput {
    width: 1.7rem !important;
    height: 2.1rem;
    margin-right: .5rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .list-scrollable {
    /* max-height: 20rem; Set the desired height */
    max-height: 50vh; /* Set the desired height */
    overflow-y: scroll; /* Enable vertical scrolling */
    overflow-x: hidden;
    border-radius: 10px;
  }
  
  .list-scrollable::-webkit-scrollbar {
    width: 0.1em; /* Set the width of the scrollbar */
  }
  
  .list-scrollable::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the background color of the scrollbar track */
  }
  
  .list-scrollable::-webkit-scrollbar-thumb {
    background-color: #888; /* Set the color of the scrollbar thumb */
  }
  
  .list-scrollable::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Set the color of the scrollbar thumb on hover */
  }

  .disabled-bg-color{
    background-color: #e9ecef;
  }
  
  /* Color start here */
  .my-color-bg-light-1 {
    background-color: #bcd2e84d;
  }
  .my-color-bg-light-1:hover {
    background-color: #BCD2E8;
    transform: scale(1.05);
  }
  /* Color end here */

  
  /* home page icon overlap start */
  .overlap-container {
    /* display: inline-block; */
    position: relative;
  }
  
  .loupe-image {
    width: 45px;
    height: 45px;
    vertical-align: middle;
  }
  
  .overlap-i {
    position: absolute;
    top: 0px;
    left: 40%;
    transform: translateX(-50%);
    font-size: 1.7rem;
    color: #000;
    font-weight: bold;
  }

  .caution {
    background-color: #feefe3;
    color: #b31412 !important;
  }
  /* home page icon overlap end */
  
  .clear_outer_back_btn {
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    /* top: 20px; */
    left: 20px;
    font-size: 30px;
  }

  /* RESPONSIVE */

  @media (max-width: 1600px) {
    .cards_ct {
      justify-content: center;
    }
  }

  @media (min-width: 752px) {
    .card_ct{
      /* grid-template-rows: 20px 50px 1fr 1fr 50px; */
      grid-template-rows: auto auto auto auto auto;
    }
  }

  /* Extra small screens (up to 320px, e.g., small phones) */
@media (max-width: 320px) {
  
}

  /* Very small screens (up to 480px, e.g., larger phones) */
@media (max-width: 480px) {
  .card__icon {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
  /* Small screens (up to 576px, e.g., phones) */
@media (max-width: 576px) {
  
}

/* Medium screens (up to 768px, e.g., tablets) */
@media (max-width: 768px) {

}

/* Large screens (up to 992px, e.g., small laptops) */
@media (max-width: 992px) {

}

/* Extra large screens (above 1200px, e.g., desktops) */
@media (min-width: 1200px) {
  
}