.categories-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  gap: 15px;
  padding: 5px;
  text-transform: uppercase;
}

.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* background-color: #bcd2e870; */
  border-radius: 10px;
  padding: 6px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.category-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-bottom: 12px;
  border-radius: 50%; /* Optional for a circular image */
}

.category-image-home {
  width: 100%;
  height: 90px;
  object-fit: cover;
  /* margin-bottom: 10px; */
  border-radius: 10%; /* Optional for a circular image */
}

.category-name {
    /* color: #8c8c8c; */
    font-weight: 550;
    font-size: .7rem;
    margin: 0;
    text-align: center;
    padding-bottom: 5px;
    text-transform: uppercase;
}

/* In your CSS file */
.categories-list {
  list-style-type: none;
  /* display: grid; */
  flex-wrap: wrap;
  gap: 0px;
  padding: 0;
  margin: 0;
}

.category-item {
  border-bottom: 1px solid #ccc;
  border-radius: 1px;
  padding: 7px;
  /* width: 100px; */
  text-align: center;
  /* background-color: #f9f9f9; */
  transition: all 0.3s ease;
  cursor: pointer;
  font-weight: bold;
  height: 48px;
}

/* .category-item:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
} */

.not-found-container {
  text-align: center;
  margin: 50px auto;
}
.not-found-container h2 {
  color: #ff6b6b;
  font-size: larger;
}