/* The alert message box */
.alert {
  position: fixed;
  padding: .8rem .8rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  z-index: 10000;
  width: 100%;
  height: 3.5rem;
  font-size: 1.1rem;
}
.error {
  color: #fff;
  background-color: red;
  border-color: red;
}

.success {
  color: #fff;
  background-color: green;
  border-color: green;
}

.warning {
  color: black;
  background-color: orange;
  border-color: orange;
}

.primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 2rem;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

.hide {
  display: none;
}
