.notification-page {
  /* max-width: 600px; Adjust the maximum width as needed */
  margin: 0 auto;
  /* padding: 20px; */
}

.notification-list {
  list-style: none;
  padding: 0;
}

.notification-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item h5 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.notification-item p {
  margin: 5px 0;
  color: #666;
}

.notification-item span {
  display: block;
  font-size: 12px;
  color: #999;
}
