.splash-screen {
    width: 100%;
    /* height: 100%; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #043873; */
    background-color: #1E3F66;
    color: #fff;
  }
  
  .splash-screen .loading-dot {
    /* font-size: 100px; */
    /* margin-top: -80px; */
    font-size: .875em;
    position: relative;
    animation: ease-in-out infinite alternate;
    animation-name: run;
    animation-duration: 4s;
  }
  
  @keyframes run {
    0% {
      left: -1px;
      color: #eee;
    }
    50% {
      color: #bbb5b5;
    }
    100% {
      left: 1px;
      color: #eee;
    }
  }
